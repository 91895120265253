import React, { useState, useEffect } from 'react';
import '../assets/css/Sidebarbox.css';

export const  ListComponent =({list,displayprofile})=>{
    console.log(list)
    const selectDesignation = (value)=>{
        console.log(value)
        displayprofile(value)
         
    }
    return(
        <>
        <ul className='profile_list'>
        {list.map((suggestion, index) => (
              <li key={index} className='profile_option' onClick={()=>{selectDesignation(suggestion)}}>
                {suggestion} 
                 
              </li>
            ))}
        </ul>

        </>
    )
}