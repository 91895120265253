// input tag suggestion 
import React, { useState, useEffect } from 'react';
import '../assets/css/Sidebarbox.css';

export const StatusComponent = ({ suggestions, onSelect, isSuggestionOpen, tagRemove, className }) => {
  const [isCheckedList, setIsCheckedList] = useState(() => suggestions.map(() => false));

  useEffect(() => {
    // When isSuggestionOpen changes to false, reset the checked values
    if (!isSuggestionOpen) {
      setIsCheckedList(suggestions.map(() => false));
    }
  }, [isSuggestionOpen, suggestions]);

  useEffect(() => {
    // Logic to filter the isCheckedList based on some condition involving suggestions and tagRemove
    const updatedCheckedList = isCheckedList.map((isChecked, index) => suggestions[index] === tagRemove ? false : isChecked);
    setIsCheckedList(updatedCheckedList);
  }, [tagRemove]);

  const selectTag = (index) => {
    const updatedCheckedList = Array(isCheckedList.length).fill(false); // Initialize all statuses as unchecked
    updatedCheckedList[index] = true; // Select the clicked index
    setIsCheckedList(updatedCheckedList);
  
    const selectedValue = suggestions[index]; // Get the selected status
  
    onSelect([selectedValue]); // Call onSelect with an array containing only the selected status
  };
  ;

  return (
    <>
      {isSuggestionOpen && (
        <div className={className[0]}>
          <ul>
            {suggestions.map((suggestion, index) => (
              <li key={index}>
                <label htmlFor={`checkbox-${index}`} className='custom-checkbox'>
                  <input
                    type='checkbox'
                    name={suggestion.status}
                    className='suggestion'
                    id={`checkbox-${index}`}
                    checked={isCheckedList[index]}
                    onChange={() => selectTag(index)}
                  />
                  <img className='checkmark' style={{border:'none'}} src={suggestion.img} alt="" srcSet="" /> 
                  <span className={className[1]}>{suggestion.status}</span>
                </label>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};
