import React, { useState, useEffect } from 'react';
import '../assets/css/Sidebarbox.css';


export const SaveCancelComponent = ({toggleClick,addTag,className})=>{
  const handleClick = ()=>{
    toggleClick()
  }
  const handleTags = ()=>{
    addTag();
    toggleClick()
  }
    return(
        <>
           <div style={{background:'white'}}>
           <button className={className[0]} onClick={handleClick} >Cancel</button>
                <button className={className[1]} onClick={handleTags}>Save</button>
           </div>
        </>
    )
}