// input tag suggestion 
import React, { useState, useEffect } from 'react';
import '../assets/css/Sidebarbox.css';




export const SuggestionList = ({ suggestions, onSelect, isSuggestionOpen ,tagRemove,className,selectedOptions}) => {
  console.log(tagRemove)
  console.log(selectedOptions)
  const [isCheckedList, setIsCheckedList] = useState(() => suggestions.map(() => false));
  const [isSelectedOptions, setSelectedOptions] = useState(selectedOptions);
  const [tagList , seTagList] = useState(suggestions);
console.log(isCheckedList)

  useEffect(() => {
  

    // When isSuggestionOpen changes to false, reset the checked values
    if (!isSuggestionOpen) {
      setIsCheckedList(suggestions.map(() => false));
      console.log(isCheckedList)
    }
  }, [ ]);
 
  useEffect(() => {
    // Logic to filter the isCheckedList based on some condition involving suggestions and tagRemove
    if(selectedOptions ){
    const updatedCheckedList = suggestions.map((value, index) => selectedOptions.includes(value)? true:false);
    console.log(suggestions)
    console.log(selectedOptions)
  
    setIsCheckedList(updatedCheckedList);}
  }, [  ]);
  
  useEffect((tagRemove) => {
    // Logic to filter the isCheckedList based on some condition involving suggestions and tagRemove
    const updatedCheckedList = [...isCheckedList];
    updatedCheckedList[tagRemove] = !updatedCheckedList[tagRemove];
    
    setIsCheckedList(updatedCheckedList)
    console.log(updatedCheckedList)
  }, [ tagRemove]);

  


  

  const selectTag = (index) => {
    const updatedCheckedList = [...isCheckedList];
    updatedCheckedList[index] = !updatedCheckedList[index]; // Toggle the checked state
  
  console.log(updatedCheckedList)
    const selectedValues = updatedCheckedList
      .map((checked, idx) => (checked ? suggestions[idx] : null))
      .filter((value) => value !== null);
 
 
  if (selectedValues.length <= 5){
  setIsCheckedList(updatedCheckedList);
    onSelect(selectedValues);
  }
  };

  return (
    <>
      {isSuggestionOpen && (
        <div className={className[0]}>
          <ul>
            {suggestions.map((suggestion, index) => (
              <li key={index}>
                <label htmlFor={`checkbox-${index}`} className='custom-checkbox'>
                  <input
                    type='checkbox'
                    name={suggestion}
                    className='suggestion'
                    id={`checkbox-${index}`}
                    checked={isCheckedList[index]}
                    onChange={() => selectTag(index)}
                  />
                  <span className='checkmark'></span>
                  <span className={className[1]}>{suggestion}</span>
                </label>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};


