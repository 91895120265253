import React, { useState, useEffect, useRef } from 'react';
import '../assets/css/Dropdown.css';
import ellipImg from "../assets/images/Ellipse_11.png"


function Dropdown() {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState('Cold'); // Initialize with a default value
  const sidebarRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setDropdownOpen(false);
  };

  const updateIframeHeight = () => {

    if (sidebarRef.current) {
      const height = sidebarRef.current.scrollHeight;
      console.log(height);
      // Send the height to the parent window
      window.parent.postMessage({ iframeHeight: height }, '*');
    }
  };

  useEffect(() => {
    // When the component is mounted or contactData changes, update the iframe height
    updateIframeHeight();
  }, [ isDropdownOpen]);

  return (
    <div className={`dropdown ${isDropdownOpen ? 'open' : ''}`} ref={sidebarRef}>
      <button
        className="btn btn-primary dropdown-toggle"
        type="button"
        onClick={toggleDropdown}
        style={{
          color: selectedItem === 'Cold' ? 'blue' : 'white',
          backgroundColor: selectedItem === 'Cold' ? '#fff' :
            selectedItem === 'Approaching' ? '#000000fc' :
            selectedItem === 'Replied' ? '#fa31eb' :
            selectedItem === 'Interested' ? '#008000fc' :
            selectedItem === 'Not Interested' ? '#ff0000fa' :
            'transparent' // Default background color when none of the conditions match
        }}
      >
        {/* <svg width="20px" height="20px" viewBox="0 0 63 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="63" height="56" fill="url(#pattern0)"></rect>
          <defs>
            <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
              <image width="110" height="99" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAG4AAABjCAYAAAB6xTUlAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAEnQAABJ0Ad5mH3gAAATLSURBVHhe7Zy7axRRFIdt1EryJ9j6KH30toqlhbWFlSARwUpRSKNWvrCxEBKsggm+RcQHaGFQEYSgIgp5iKjRRMUocuVb9si63tmdmTuPeybngx+JZmZW5+Pee86d3SxzhkpMnFJMnFJMnFJMnFJMnFJMnFJMnFJMnFJMnFJMnFJMnFJMnFJMnFJMnFJMnFJMnFJMnFJMnFJMnFJMnFJMnFJMnFJMnFJMnFJMnFJMnFJMnFJMnFIKEXf24nP3ZPJD+09GFRQiDmk7h265TQcuu8FTD9yle2/aPzHKotCpcuTGS7d6cMyt2jPa+moSy6PwNW5uftEdG3nakicRiXcmpttHGaGUVpy8npp3u4/f/UcgYTo9dO6RrYmBlCZOYJQhq1sg2XrwequwYZRWzcLibzc6ueD23n7vtoxPu/WjU63vT0/MuRcff7aPipfSxQkIkvXPlyqn0vtvv/+VlRQExkxl4oCRhSCfOAmjkCKnLK69+uYV5QsjMFYqFScwshDkEydhdFLksFYWxczCL6+gXjn/7Ev77LioRZyAmF7Tp4RRWoTAow8/eeX0ClMq62Fs1CoOEELz7hPWHQSGVKP91rWksCbGRu3ihH7FS2cQnbWQyTNNSmKcLqMRBxQvvt4vKRybdgp9PPvDKyVNTFxKOrfO0iTtGuiTkib0e7ERpTjIsvYRqUJ7NfO7brzziukXptnYiFacwNrnE5UUBCb1gVl6OElnLzc7M+PWrV3jlq9YmStnTp5oXymc6MUBlWTStllSGK2+ChQRPkG+UIXKaLt59WqQtKEjh1vXKQoV4iBr4SJhQ7tz+qQnSyMPabJnibSBgQGvkH7hvAvDw63rFIkacULWqZMwWrvbB6bNHVdmvcJo1KXpDpXG+WWgThwgIUvVKekefcCoolWQdBKrNFApDqg6++13+uIbfT5ilgZqxUHedY/QOiQRuzRQLU7o96goKVSe3VOnBmnQCHFA7+aT0y+sldI2aJEGjREHvKMsT9EiTfv+fYNeKf1StTRolDhg9OSRR1j3aJR9cpJShzRonDhAXp6Kk7BesjWVZsqsSxo0UhxQdOSVR6U6Pnappzy2vx5PTLRfrXoaKw5C5HEe8nz7k/wdG8510mhxECpv8sWbf+TFIA0aLw5C5bFmbt+2zW3euCEKabAkxAH7lD4xadLZ68XCkhAX0iJIYpO3JNa4rA9hkxKTvEaLC1nbkhKLvMaKK0OaJAZ5jRRXpjQJ1+d16qJx4qqQJqlTXqPEVSlNwjO9OmiMuDqkSegRq6YR4oqQFtoylPlhTB/qxYVKkwqRNx/xve+YNKm60lQtjhsVIo1zO4sLnqD7jkub7uuViVpxSAsZITww9d3kvO8ak3DdKlAprghpSSAz5Nqkit+mpE5cqLQ0RUTolMm/L+0HLvOiSlwV0oTQKbPs/k6NuCqlQWiVSfiASlmoEMdNz3sTOQ/pecjzyaDO8NplTZnRi0Oa76akSYg0IbSxZ8otmrnPX+MWV7c04Bq+62dJ1l/tkYZoxYVIY/uqCGkCUybFRp7QeiwpcQL/6aypaveiTlQUJ8b/mDilmDilmDilmDilmDilmDilmDilmDilmDilmDilmDilmDilmDilmDilmDilmDilmDilmDilmDilmDilmDilmDilmDiVOPcHSgmzurHfB2wAAAAASUVORK5CYII=" />
            </pattern>
          </defs>
        </svg> */}
        <img src={ellipImg}
         alt="Leads NXT" 
         style={{
         width: '20px',
         height: '20px',
         marginLeft: '8px',
         marginTop: '5px',
         marginRight: '7px',
         marginBottom: '5px',
         }}
         />
        <span className={`approach-class`}>{selectedItem}</span>
        <span
          style={{
            position: 'absolute',
            left: '80%',
            marginTop: '-32px',
            width: '21%',
            height: 'auto',
            marginTop: '2px',
            
          }}
        >
          <i className={`${selectedItem === 'Cold' ? 'arrow' : 'arrow1'} ${isDropdownOpen ? 'up' : 'down'}`}></i>

        </span>
      </button>
      {isDropdownOpen && (
        <ul className="dropdown-menu" style={{ display: 'block' }}>
          <li onClick={() => handleItemClick('Cold')}>
            <span className="dropdown-item cold" >Cold</span>
          </li>
          <li onClick={() => handleItemClick('Approaching')}>
            <span className="dropdown-item approaching">Approaching</span>
          </li>
          <li onClick={() => handleItemClick('Replied')}>
            <span className="dropdown-item replied">Replied</span>
          </li>
          <li onClick={() => handleItemClick('Interested')}>
            <span className="dropdown-item interested">Interested</span>
          </li>
          <li onClick={() => handleItemClick('Not Interested')}>
            <span className="dropdown-item notinterested">Not Interested</span>
          </li>
        </ul>
      )}
    </div>
  );
}

export default Dropdown;
