import React, { useState, useEffect, useRef } from 'react';
import 'font-awesome/css/font-awesome.min.css';
import '../assets/css/Bannerbox.css';
import axios from 'axios';
import Cookies from 'js-cookie'; // Import js-cookie library
import Skeleton from 'react-loading-skeleton'; // Import the loading skeleton
import 'react-loading-skeleton/dist/skeleton.css';
import ellipImg from "../assets/images/Ellipse_11.png";
import editNoteImg from "../assets/images/edit_note.png";
import deleteNoteImg from "../assets/images/delete.png";

function Bannerbox() {
  const [isLoading, setIsLoading] = useState(true);
  const [urlParam, setUrlParam] = useState('');
  const [platformidParam, setPlatformidParam] = useState('');
  const [notestypeParam, setNotesTypeParam] = useState('');
  const [sParam, setSParam] = useState('');
  const [notescategoryParam, setNotescategoryParam] = useState('');
  const [noteText, setNoteText] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [hasNotes, setHasNotes] = useState(false);
  const [noteIdToDelete, setNoteIdToDelete] = useState(null);
  const [isArrowToggled, setIsArrowToggled] = useState(true);
  const textareaRef = useRef(null);
  const [textareaWidth, setTextareaWidth] = useState('auto');
  
 


  // useEffect(() => {
  //   // Assume you get the session ID from some API or other source
  //   const sessionId = '9c661010-859d-497b-8ff1-9cb414a77565'; // Replace with the actual session ID value
    
  //   const createSessionCookie = (sessionId) => {
  //     // Create a session cookie with a name and value
      // Cookies.set('s', '354a0210-580e-4bf5-b454-902296235dea', { expires: 1 }); // Replace with your cookie name and expiration settings
  //     Cookies.set('yourSessionCookieName', sessionId, { expires: 1 }); // Replace with your cookie name and expiration settings
  //   };
  //   console.log('data',Cookies.get('yourSessionCookieName'));
  //   // Create the session cookie
  //   createSessionCookie(sessionId);
    
  //   // Other code...
  // }, []);

  useEffect(() => {

    // Get the session ID from cookies
    const sessionId = Cookies.get('leadsNxt_sid'); // Replace with your cookie name
    // const sessionId = sParam;
    if (sessionId) {
      
      // Use the session ID as needed
      setSParam(sessionId);
    }

    fetchNotesData();

  }, [sParam]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false); // Set isLoading to false when loading is complete
    }, 5000); // Simulate a 2-second loading delay
  }, []);

  useEffect(() => {
    // Get the query parameters from the iframe's URL
    const queryString = window.location.search;

    // Parse the query string into an object
    const params = new URLSearchParams(queryString);
    // Retrieve the values of specific query parameters
    // const sParamValue = params.get('s');
    const notesTypeParamValue = params.get('notes_type');
    const PlatformidParamValue = params.get('platformid');
    const urlParamValue = params.get('url');
    const sParamValue = params.get('s');
    const notescategoryParamValue = params.get('notes_category');
    console.log(sParamValue)
  let getCookie = setInterval(() => {
    const cookieString = document.cookie;
    console.log('cookieString ',cookieString)
    console.log(cookieString != '')
    if(cookieString != ''){
      clearInterval(getCookie)
      console.log('cookieString ',cookieString)
      const cookies = cookieString.split(';');
  for (let cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split('=').map(c => c.trim());
    console.log(cookieName)
    if(cookieName == 'leadsNxt_sid' ){
      console.log('cookie found')
    console.log(cookieValue)
    setSParam(cookieValue)
   
  }
  }
    }
  }, 500);

    // Set the state variables with the obtained values

    //setSParam(sParamValue || '');
    setNotesTypeParam(notesTypeParamValue || '');
    setPlatformidParam(PlatformidParamValue || '');
    setUrlParam(urlParamValue || '');
    setNotescategoryParam(notescategoryParamValue || '');
  }, []);

  useEffect(() => {
    sendWidthToParent();
    sendHeightToParent();

    window.addEventListener('resize', sendWidthToParent);
    window.addEventListener('resize', sendHeightToParent);

    return () => {
      window.removeEventListener('resize', sendWidthToParent);
      window.removeEventListener('resize', sendHeightToParent);
      window.removeEventListener('load', sendHeightToParent);
      window.removeEventListener('load', sendWidthToParent);
    };
  }, [noteText, isEditing, isAdding, isArrowToggled, hasNotes,isLoading]);



  useEffect(() => {
    adjustTextareaWidth();

  }, [noteText, isEditing, isAdding, isArrowToggled, hasNotes,isLoading]);

  useEffect(() => {
    adjustTextareaHeight();
  }, [noteText, isEditing, isAdding, isArrowToggled, hasNotes,isLoading]);

  const sendWidthToParent = () => {
    const contentWidth = document.body.scrollWidth;

    window.parent.postMessage({ type: 'setWidth', width: contentWidth }, '*');
  };

  const sendHeightToParent = () => {
    const contentHeight = document.body.scrollHeight;
    window.parent.postMessage({ type: 'setHeight', height: contentHeight }, '*');
  };

  const adjustTextareaWidth = () => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      if (textarea.scrollWidth >= 400) {
        // textarea.style.overflowX = 'auto';
        textarea.style.width = '400px';
        textarea.style.whiteSpace = 'normal';
      } else {
        textarea.style.overflowX = 'hidden';

        textarea.style.width = `${textarea.scrollWidth}px`;
      }
    }
  };


  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleNoteInputChange = (event) => {
    const textarea = event.target;
    setNoteText(event.target.value);
    adjustTextareaWidth();
    adjustTextareaHeight();
  };

  const handleToggleArrowClick = () => {

    setIsArrowToggled(!isArrowToggled);
    // Send width and height to parent when arrow is toggled
    // sendWidthToParent();

    // Adjust the width based on whether content is minimized or maximized
    if (isArrowToggled) {
      // When minimizing, set a specific width (e.g., 200px)
      document.body.style.width = '160px';
      
    }
   
      
    
   
    sendHeightToParent();
  };

  const handleEditClick = () => {
    
    if (noteText.trim() === '') {
      setNoteText('');
      setIsEditing(false);
      setHasNotes(false);
      
      
       // textareaRef.current.style.cursor = 'auto';
       textareaRef.current.focus();
    } else {
      setIsEditing(true);
      setIsArrowToggled(true);
      
    
      //  textareaRef.current.style.cursor = 'auto';
      if (textareaRef.current) {
        textareaRef.current.focus();
        const textarea = textareaRef.current;
        // Place the cursor at the end of the text
        textarea.selectionStart = textarea.selectionEnd = textarea.value.length;
      }
    }
  
  };

  const handleEditSaveClick = async () => {
    if (noteText.trim() === '') {
      setIsEditing(true);
      setHasNotes(true);
      return;
    } else {
      try {
        axios.defaults.baseURL = 'https://leadsnxt-api.recruitnxt.in';
        const formData = new FormData();
        formData.append('s', sParam);
        formData.append('notes_id', noteIdToDelete);
        formData.append('notes', noteText);
        formData.append('notes_type', notestypeParam);

        const response = await axios.post('/update_notes.php', formData);
        const responseData = response.data.result;

        if (responseData === 'success') {
          setIsEditing(false);
          
          console.log('Note updated successfully');
        } else {
          console.log('Error updating note');
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    }
  };

  const handleDeleteClick = async () => {
    try {
      if (!noteIdToDelete) {
        console.log('No note ID to delete');
        return;
      }

      axios.defaults.baseURL = 'https://leadsnxt-api.recruitnxt.in';
      const formData = new FormData();
      formData.append('s', sParam);
      formData.append('notes_id', noteIdToDelete);

      const response = await axios.post('/delete_notes.php', formData);
      const responseData = response.data.result;

      if (responseData === 'success') {
        setNoteText('');
        setIsEditing(false);
        setHasNotes(false);
        document.body.style.width = '162px';
        // Resize the iframe after deleting the note
        sendWidthToParent();
        sendHeightToParent();


        console.log('Note deleted successfully');
      } else {
        console.log('Error deleting note');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const handleEditCancelClick = () => {
    setIsEditing(false);
    setHasNotes(true);
    fetchNotesData();
   // textareaRef.current.style.cursor = 'default';
  };

  const handleCreateClick = () => {
    setIsAdding(true);

    setTextareaWidth('auto');
    
  };

  const fetchNotesData = async () => {
    axios.defaults.baseURL = 'https://leadsnxt-api.recruitnxt.in';
    const formData = new FormData();
    formData.append('s', sParam);
    // formData.append('platformid', platformidParam);
    // formData.append('url', urlParam);
    formData.append('platformid', 1);
    formData.append('url', 'https://www.linkedin.com/in/princekumar294/');

    try {
      const response = await axios.post('/get_notes.php', formData);
      const notesData = response.data.data;
console.log(notesData)
      if (response.data.result === 'success' && notesData != undefined) {
        const initialNote = notesData[0].note;
        setNoteText(initialNote);
        setNoteIdToDelete(notesData[0].note_id);
        setHasNotes(true);
        console.log('fetched');
        // window.parent.postMessage('contentChanged', '*');
        return notesData;
      } else {
        console.log('error');
        return [];
      }
    } catch (error) {
      console.error('An error occurred:', error);
      return [];
    }
  };


  const handleCreateSave = async () => {
    if (noteText.trim() === '') {
      setIsAdding(false);
      setIsEditing(false);
      setHasNotes(false);
    } else {
      try {
        axios.defaults.baseURL = 'https://leadsnxt-api.recruitnxt.in';
        const formData = new FormData();
        formData.append('s', sParam);
       
        // formData.append('platformid', platformidParam);
        // formData.append('url', urlParam);
        formData.append('platformid', 1);
        formData.append('url', 'https://www.linkedin.com/in/princekumar294/');
        formData.append('notes', noteText);
        formData.append('notes_category', notescategoryParam);

        const response = await axios.post('/create_notes.php', formData);
        const responseData = response.data.result;
        
        if (responseData === 'success') {
          const notesData = await fetchNotesData();
          
          // console.log(notesData);
          if (notesData && notesData.length > 0) {
            const Note = notesData[0];
            setNoteText(Note.note);
            setIsAdding(false);
            setHasNotes(true);
            document.body.style.height = 'auto';
            
          } else {
            setIsAdding(false);
            setHasNotes(true);
          }
          console.log('registered');
        } else {
          console.log('error');
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    }
  };

  const handleCreateCancel = () => {
    setNoteText('');
    setIsAdding(false);
    setHasNotes(false);
    document.body.style.width = '160px';
  }
  return (
    
    
    <div className="container">
      {hasNotes ? (
        <>
          <div className="box open_note_box">
            {isArrowToggled ? (

              <div className="button-container note-box-container" >
                <span>
                  <img
                    src={ellipImg}
                    alt="Leads NXT"
                    style={{

                      width: '20px',
                      height: '20px',
                      marginLeft: '10px',
                    }}
                  />
                </span>
                
                <span
                  style={{
                    marginLeft: '8px',
                    fontSize: '16px',
                    fontWeight: '500',
                    lineHeight: '19px',
                    color: '#000000',
                     position: 'absolute',
                     marginTop: '1px',
                  }}
                >
                  Private Note
                </span>

                {isEditing ? (
                  <>
                    <div
                      onClick={handleEditSaveClick}
                      className="btn_box Edit_note2_save"
                      id="save_note"
                      style={{
                        // Add any additional styles for this element
                      }}
                    >
                      Save
                    </div>
                    <div
                      onClick={handleEditCancelClick}
                      className="btn_box Edit_note2_cancel"
                      id="cancel_note"
                      style={{
                        // Add any additional styles for this element
                      }}
                    >
                      Cancel
                    </div>
                    <span
                      style={{
                        cursor: 'pointer',
                        // position: 'absolute',
                        top: '-3px',
                        left: '568px',
                        display: 'inline',
                        // transform: 'rotate(182deg)',
                      }}
                      className="note-box-arrow"
                    >
                      {/* <img
                    src="chrome-extension://dcjakikphlfknfcaaomhjejlkbiijpni/img/dropdown_arrow.png"
                    alt="edit_pen"
                    style={{
                      width: '13px',
                      height: '8px',
                    }}
                  /> */}
                      {/* Toggle the arrow icon based on isArrowToggled state
                  {isArrowToggled ? (
                    <i onClick={handleToggleArrowClick} className="fa fa-chevron-up" aria-hidden="true" style={{ height: '20px' }}></i>
                  ) : (
                    <i onClick={handleToggleArrowClick} className="fa fa-chevron-down" aria-hidden="true" style={{ height: '20px' }}></i>
                  )} */}

                    </span>
                  </>
                ) : (
                  <>
                    <span

                      style={{
                        //cursor: 'pointer',
                        // position: 'absolute',
                        // top: '-4px',
                        // left: '520px',
                        // display: 'inline',
                        //transform: 'rotate(182deg)',

                      }}
                      className="note-box-arrow"
                    >


                      <i onClick={handleToggleArrowClick} className="fa fa-chevron-up" aria-hidden="true" style={{ height: '20px', float: 'right', marginRight: '10px' }}></i>



                    </span>
                    <span
                      onClick={handleEditClick}
                      style={{
                        float: 'right',
                        cursor: 'pointer',
                        paddingRight: '10px',
                        //  position: 'absolute',
                        // top: '5px',
                        // left: '535px',
                        display: 'inline',
                        // transform: 'rotate(182deg)',
                        marginTop:'-3px',
                      }}
                      className="note-box-arrow"
                    >
                      <img
                        src={editNoteImg}

                        alt="edit_pen"
                        style={{
                          width: '13px',
                          height: '13px',
                        }}
                      />

                    </span>


                  </>
                )}

                {/* Toggle text area content based on isArrowToggled state */}
                {isArrowToggled && (
                  <>
                    <textarea
                      ref={textareaRef}
                      onChange={handleNoteInputChange}
                      placeholder="Enter your note here"
                      value={noteText}
                      className="note_textarea"
                      style={{
                        margin: '10px',
                        border: 'none',
                        //  marginRight: '10px',
                        width: textareaWidth,
                        fontSize: '12px',
                        overflowX: 'hidden',
                        // padding: '10px',
                        resize: 'none',
                        maxHeight: '75px', // Set a maximum height
                        minHeight: '30px',  // Set a minimum height
                        maxWidth: '400px',
                        minWidth: '304px',
                        overflowY: 'auto', // Enable vertical scrolling
                        whiteSpace: 'nowrap',
                        cursor: 'default',
                      }}
                      readOnly={!isEditing}

                    ></textarea>

                    <span
                      style={{
                        //   cursor: 'pointer',
                       // position: 'absolute',
                         bottom: '-2px',
                        //   left: '547px',


                      }}
                      className="note-box-arrow"
                    >
                      <img onClick={handleDeleteClick} src={deleteNoteImg} style={{ float: 'right', marginRight: '10px' }} />

                    </span>
                  </>
                )}


              </div>
            ) : (

              <>
                
                  {isLoading ? (
                    // Display loading skeleton
                    <>
                      <span>
                      <Skeleton width={20} height={20} style={{ marginLeft: '12px' }} />
                      </span>
                      <span style={{ marginLeft: '-3px', padding: '7px',}}>
                      <Skeleton width={70} height={15}  />
                      </span>
                      <span>
                      <Skeleton width={15} height={15} style={{}} />
                      </span>
                      <span className="note-box-arrow">
                      <Skeleton width={15} height={15}  style={{ height: '20px', marginLeft: '6px' }} />
                      </span>
                    </>
                  ) : (
                    // Render your actual content here
                    <>
                      <span>
                        <img
                          src={ellipImg}
                          alt="Leads NXT"
                          style={{
                            width: '20px',
                            height: '20px',
                            marginLeft: '9px',
                          }}
                        />
                      </span>
                      <span
                        style={{
                          marginLeft: '-6px',
                          fontSize: '14px',
                          fontWeight: '500',
                          lineHeight: '19px',
                          color: '#000000',
                          // position: 'absolute',
                          padding: '7px',
                          marginTop: '-2px',
                        }}
                      >
                        Private Note
                      </span>
                      <span onClick={handleEditClick}>
                        <img
                          src={editNoteImg}
                          alt="edit_pen"
                          style={{
                            width: '13px',
                            height: '13px',
                            marginLeft: '9px',
                          }}
                        />
                      </span>
                      <span className="note-box-arrow">
                        <i onClick={handleToggleArrowClick} className="fa fa-chevron-down" aria-hidden="true" style={{ height: '20px', marginLeft: '2px' }}></i>
                      </span>
                      </>
                  
                  )}
                
              </>

            )}
          </div>

        </>
      ) : (
        <div className="box open_note_box">
          {isAdding ? (
            <>
              <div className="button-container note-box-container" >
                <span>
                  <img
                    src={ellipImg}
                    alt="Leads NXT"
                    style={{

                      width: '20px',
                      height: '20px',
                      marginLeft: '10px',
                      //float: 'left',
                    }}
                  />
                </span>
                <span
                  style={{
                    marginLeft: '11px',
                    fontSize: '16px',
                    fontWeight: '500',
                    lineHeight: '19px',
                    color: '#000000',
                     position: 'absolute',

                  }}
                >
                  Private Note
                </span>


                <div
                  onClick={handleCreateSave}
                  className="btn_box private_note2_save"
                  id="save_note"
                  style={{
                    // Add any additional styles for this element
                  }}
                >
                  Save
                </div>
                <div
                  onClick={handleCreateCancel}
                  className="btn_box private_note2_cancel"
                  id="cancel_note"
                  style={{
                    // Add any additional styles for this element
                  }}
                >
                  Cancel
                </div>


                <textarea
                  ref={textareaRef}
                  placeholder="Enter your note here"
                  value={noteText}
                  onChange={handleNoteInputChange}
                  className="note_textarea"
                  style={{
                    border: 'none',
                    // width: '100%',
                    //  padding: '10px',
                    margin: '10px',
                    // borderRadius: '5px',
                    // color: 'rgba(0, 0, 0, 0.45)',
                    // border: '0.5px solid rgba(0, 0, 0, 0.45)',
                    fontSize: '12px',
                    whiteSpace: 'nowrap',
                    overflowX: 'hidden',
                    cursor: 'auto',
                    resize: 'none',
                    maxHeight: '75px', // Set a maximum height
                    minHeight: '60px',  // Set a minimum height
                    maxWidth: '380px',
                    minWidth: '304px',

                  }}
                  readOnly={!isAdding}

                ></textarea>
                {/* <img
                  src="chrome-extension://dcjakikphlfknfcaaomhjejlkbiijpni/img/delete.png"
                  alt="edit_pen"
                  style={{
                    width: '11px',
                    height: '16px',
                    top: '95px',
                    left: '279px',
                    position: 'absolute',
                    cursor: 'pointer',
                  }}
                  className="delete_img"
                /> */}

              </div>
            </>
          ) : (
            <>
             {isLoading ? (
        // Display loading skeleton while isLoading is true
        <>
          <span>
            <Skeleton width={20} height={20} style={{ marginLeft: '12px' }} />
          </span>
          <span
            style={{
              marginLeft: '2px',
              fontSize: '15px',
              fontWeight: '500',
              lineHeight: '19px',
              color: '#000000',
              padding: '7px',
            }}
          >
            <Skeleton width={89} height={15} />
          </span>
          <div style={{ display: 'flex', marginLeft: '138px', position: 'absolute' }}>
            <Skeleton width={15} height={15} />
          </div>
        </>
      ) : (
        // Render your actual content when isLoading is false
        <>
          <span>
            <img
              src={ellipImg}
              alt="Leads NXT"
              style={{
                width: '20px',
                height: '20px',
                marginLeft: '12px',
              }}
            />
          </span>
          <span
            style={{
              marginLeft: '-3px',
              fontSize: '14px',
              fontWeight: '500',
              lineHeight: '19px',
              color: '#000000',
              padding: '7px',
              marginTop: '-3px',
            }}
          >
            Private Note
          </span>
          
          <div style={{ display: 'flex', marginLeft: '138px', position: 'absolute' }}>
            <i onClick={handleCreateClick} className="fa fa-plus" style={{ width: '12px' }} aria-hidden="true"></i>
          </div>
        </>
      )}
            </>

          )}
        </div>
      )}
    </div>
    
    
  );
}

export default Bannerbox;
