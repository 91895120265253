
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Bannerbox from './components/Bannerbox';
import Sidebarbox from './components/Sidebarbox';
import Dropdown from './components/Dropdown';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/notes" element={<Bannerbox />} />
        <Route path="/profile" element={<Sidebarbox />} />
        <Route path="/dropdown" element={<Dropdown />} />
        
        {/* Add more routes as needed */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
