import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import '../assets/css/Sidebarbox.css'; // Import the external CSS file
import Cookies from 'js-cookie'; // Import js-cookie library
import Skeleton from 'react-loading-skeleton'; // Import the loading skeleton
import 'react-loading-skeleton/dist/skeleton.css'
import logoImg from "../assets/images/logo_1.png"
 
import settingImg from "../assets/images/setting.png"
import facebookImg from "../assets/images/fb.png"
import twitterImg from "../assets/images/twitter.png"
import linkedinImg from "../assets/images/linkedin.png"
import cross from "../assets/images/cross.png";
 
import tagImg from "../assets/images/tagImg.png"
import HQImg from "../assets/images/HQImg.png"
import contactImg from "../assets/images/contactImg.png"
import socialMediaImg from "../assets/images/socialMediaImg.png"
import NAimg from "../assets/images/NAimg.png"
import emailImg from "../assets/images/verifiedEmailImg.png"
import vector from "../assets/images/Vector.png"
import arrowImg from "../assets/images/arrowImg.png"
import createProfile from "../assets/images/createProfile.png"
import activeprofileSearch from "../assets/images/activeprofileSearch.png"
import editContactImg from "../assets/images/editContactImg.png"
import likeBtnImg from "../assets/images/likeBtnImg.png"
import copyImg from "../assets/images/copyImg.png"
import mailImg from "../assets/images/mailImg.png"
import profileImg from "../assets/images/profileImg.png"
import questionableStatusImg from "../assets/images/questionableStatusImg.png"
import invalidStatusImg from "../assets/images/invalidStatusImg.png"
import verified from "../assets/images/verified.png"
import noStatusImg from "../assets/images/noStatusImg.png"


// importing components
import { SuggestionList } from './SuggestionList';
import { SaveCancelComponent } from './SaveCancelComponent';
import { ListComponent } from './ListComponent';
import {StatusComponent} from './StatusComponent'




function Sidebarbox() {
  const [isLoading, setIsLoading] = useState(true);
  const [contactData, setContactData] = useState([]);
  const [socialMediaData, setSocialMediaData] = useState([]);
  const sidebarRef = useRef(null);
  const [sParam, setSParam] = useState('');
  const [urlParam, setUrlParam] = useState('');
  const [platformidParam, setPlatformidParam] = useState('');
  const [encryptParam, setEncryptParam] = useState('');
  const [cidParam, setCidParam] = useState('');
  const [curlParam, setCurlParam] = useState('');
  let tagArr = ['Decision Maker',
   'Advisor',
   'Manager',
   'Technical',
   'Critical Thinker',
   'Analysis',
   'Data Intrepreter',
   'Virtual Reality',
   'Team Player',
  'Visualization'];
  let profileArr = [
    'Developer',
  'Designer',
  'Manager',
  'Recruiter',
  'Marketer']
  let statusArr = [
  { status:'Verified',img:verified,hashcodeColor: "##1EC702"},
  {status:'Questionable',img:questionableStatusImg,hashcodeColor: "#FEBF02"},
  {status:'Invalid',img:invalidStatusImg,hashcodeColor: "#e50606"},
  {status:'No status',img:noStatusImg,hashcodeColor: "#6f7b8d"},
]
//   let statusArr = [
//     {status:'Verified', img:verified},
//   {status:'Questionable',img:questionableStatusImg},
//   {status:'Invalid',img:invalidStatusImg},
//   {status:'No status',img:questionableStatusImg},
// ]
  // rag suggestion code 
// const [inputValue,setInputValue] = useState('');
const [suggestions,setSuggestions] = useState(tagArr);
const [status,setStatus] = useState(statusArr);
const [isSuggestionOpen,setIsSuggestionOpen] = useState(false);
const [isStatusDropdownOpen,setIsStatusDropdownOpen] = useState(false);
const [tagRotateArrow,settagRotateArrow] = useState(false);
const [isProfileListOpen,setIsProfileListOpen] = useState(false);
const [isSelectedProfile,setisSelectedProfile] = useState('Developer');
const [selectedProfileNum,setSelectedProfileNum] = useState('');
const [profileSearch,setProfileSearch] = useState(false)
const [inputValue, setInputValue] = useState('');
const [statusInputValue, setstatusInputValue] = useState('');
const [selectedtags, setSelectedtags] = useState([])
const [selectedStatus, setSelectedStatus] = useState([])
const [removeCheck, setRemoveCheck] = useState('');
const [designationSaved ,setDesignationSaved] = useState(false) ;
const [addDesignation ,setAddDesignation] = useState('') ;
const [divEditable ,setDivEditable] = useState(false) ;
const [manageTagsdivEditable ,setmanageTagsdivEditable] = useState(false) ;
const [profileArrList ,setProfileArrList] = useState(profileArr) ;
const [overlay ,setOverlay] = useState('') ;
const [rotateArrow ,setRotateArrow] = useState(0) ;
const [itemGap ,setItemGap] = useState('6px') ;
const [itemMarginBottom,setitemMarginBottom] = useState('35px');
const [dataMode,setDataMode] = useState('encrypted');
const [updateSelectedTags,setUpdateSelectedTags] = useState([])
const [updateSelectedStatus,setUpdateSelectedStatus] = useState([])


 

 
const handleInputValue = (targetValue)=>{
  setInputValue(targetValue)
 let filterValue = suggestions.filter((tag) =>
  tag.toLowerCase().includes(inputValue.toLowerCase())
);
 if(inputValue != '')
 setSuggestions(filterValue)
  setIsSuggestionOpen(true)


 
}
const handleStatusInputValue = (targetValue) => {
  setstatusInputValue(targetValue);

  if (targetValue == '') {
    // If input value is empty, show all status
    setStatus(statusArr.map(status => status.status));
  } else {
    // Filter the status based on input value
    const filterValue = status.filter((status) =>
      status.status.toLowerCase().includes(targetValue.toLowerCase())
    );
    console.log('filterValue ',filterValue)
    setStatus(filterValue );
     // Open the status dropdown
     setIsSuggestionOpen(true);
  }

 
};


useEffect(() => {
  // When the input value is empty, display the original dropdown list
  if (inputValue == '') {
    setSuggestions(tagArr);
  }
}, [inputValue ]);


useEffect(() => {
  // When the input value is empty, display the original dropdown list
  if (statusInputValue == '') {
    setStatus(statusArr);
  }
}, [statusInputValue]);
const handleSelectedSuggestion =(selectedSuggestion)=>{
  setNewTags(false);
  // setInputValue(selectedSuggestion[0])
  // if(newTags ==false)
  let updateSelectedTags = selectedSuggestion;
  // updateSelectedTags.push(selectedSuggestion)
  setSelectedtags(updateSelectedTags)
  setInputValue('')
  // setIsSuggestionOpen(false)
}
const handleSelectedStatus =(selecetedStatus)=>{
  console.log('selecetedStatus ',selecetedStatus)
  selectedStatus.map((status,index)=>{
    console.log(status)
 } )
  // setInputValue(selectedSuggestion[0])
  setSelectedStatus(selecetedStatus)
  setstatusInputValue('')
  // setIsSuggestionOpen(false)
}

 
const cancelTagText =()=>{
  if(inputValue !=''){
  setInputValue('')
  setIsSuggestionOpen(false)}
}
const rotateArrowImg =()=>{
   setIsSuggestionOpen(!isSuggestionOpen)
  !isSuggestionOpen ? settagRotateArrow(180):settagRotateArrow(0)
  
 
}
 

  const handleCancelClick = (indexRemove ) => {
  let updatedTags = selectedtags.filter((_,index)=>index !== indexRemove);
  setSelectedtags(updatedTags);
  setUpdateSelectedTags(updatedTags);
    // selectedtags.map((value,index)=>index == indexRemove ?  setSelectedtags(value):'' );
    setRemoveCheck(indexRemove)
    if(selectedtags.length  == 1){
      setIsSuggestionOpen(false);
      setNewTags(false);
    }
  };
  const handleStatusCancelClick  = (indexRemove ) => {
    let updatedTags = selectedStatus.filter((_,index)=>index !== indexRemove);
    setSelectedStatus(updatedTags);
    setUpdateSelectedStatus(updatedTags);
      selectedStatus.map((value,index)=>index == indexRemove ?  setRemoveCheck(value):'' );
      if(selectedStatus.length  == 1){
        setIsStatusDropdownOpen(false);
        // setNewTags(false);
      }
    };

  const handleFocus = (event) => {
    event.target.style.outline = 'none'; // Remove default outline
    event.target.style.border = 'none'; // Remove default outline
    // Add any other modifications to styles or behaviors here
  };

// profileSectionn 
const handleSelectedProfiles =(selectedprofile)=>{
  setisSelectedProfile(selectedprofile[0])
  console.log(selectedprofile.length)
  selectedprofile.length > 1 ?setSelectedProfileNum(`, +${selectedprofile.length-1}`) :setSelectedProfileNum('');
 
}
const togggleProfileSearch = ()=>{
  if(contactData.length > 0){
  setIsProfileListOpen(false)
  setDivEditable(!divEditable)
  setDesignationSaved(true)
 overlay == ''?  setOverlay('overlay') : setOverlay('');
 }
 }

 const profileDropdown = ()=>{
  if(contactData.length > 0){
  !isProfileListOpen ? setRotateArrow(180):setRotateArrow(0)
   setIsProfileListOpen(!isProfileListOpen)
  setProfileSearch(false)
  setDivEditable(false)
  }
 }

 const displaySelectedDesig =(value)=>{
  console.log(value)
  setAddDesignation(value)
  setDesignationSaved(true)
  setProfileSearch(false)
  setDivEditable(true)
 }

 const profileCancel= ()=>{
  setDivEditable(false)
  setOverlay('')
  setDesignationSaved(false)
 }

 const addDesig = (value)=>{
  console.log(value)
  setAddDesignation(value)
  if(addDesignation == ''){
    setProfileArrList(profileArr)
  }else{
  let filterValue = profileArrList.filter((tag) => tag.toLowerCase().includes(addDesignation.toLowerCase()));
 setProfileArrList(filterValue)
  setIsSuggestionOpen(true)

 }

 }

  useEffect(() => {
  // When the input value is empty, display the original dropdown list
  if (addDesignation == '') {
    setProfileArrList(profileArr);
  }
}, [addDesignation ]);
 const SaveDesignation =(saveDesignation)=>{
  console.log(saveDesignation)
const updateProfileArr = [...profileArr,addDesignation]
setProfileArrList(updateProfileArr)

 }

 const handleMouseOver = (index) => {
  if(contactData.length >0){
  const newHoverStates = [...hoverEffect]
  newHoverStates[index] = true;
  setHoverEffect(newHoverStates);
  setOnHoverShow(true)}
};

const handleMouseOut = (index) => {
  if(contactData.length >0){
  const newHoverStates = [...hoverEffect]
  newHoverStates[index] = false;
  setHoverEffect(newHoverStates);
  setOnHoverShow(false)}

};

const phoneHandleMouseOver = (index) => {
  if(contactData.length >0){
  const newHoverStates = [...phoneHoverEffect]
  newHoverStates[index] = true;
  setPhoneHoverEffect(newHoverStates);}
};

const phoneHandleMouseOut = (index) => {
  if(contactData.length >0){
  const newHoverStates = [...phoneHoverEffect]
  newHoverStates[index] = false;
  setPhoneHoverEffect(newHoverStates);}
};
 

 
 

  // useEffect(() => {
  //   // Assume you get the session ID from some API or other source
  //   const sessionId = sParam; // Replace with the actual session ID value

  //   const createSessionCookie = (sessionId) => {
  //     // Create a session cookie with a name and value
  //     // Cookies.set('yourSessionCookieName', sessionId, { expires: 1 }); // Replace with your cookie name and expiration settings
  //     Cookies.set('s', '354a0210-580e-4bf5-b454-902296235dea', { expires: 1 }); // Replace with your cookie name and expiration settings
  //   };

  //   // Create the session cookie
  //   createSessionCookie(sessionId);

  //   // Other code...
  // }, [sParam]);

  useEffect(() => {

    // Get the session ID from cookies
    const sessionId = Cookies.get('leadsNxt_sid'); // Replace with your cookie name
    // const sessionId =sParam;
    if (sessionId) {
      // Use the session ID as needed
      setSParam(sessionId);
    }

    // Simulate loading data (replace with your data fetching logic)
    setTimeout(() => {
      // Once data is loaded, set isLoading to false
      setIsLoading(false);

    }, 2000); // Simulate a 2-second loading time

    // fetchContactData();


  }, [sParam]);

  useEffect(() => {
    // Get the query parameters from the iframe's URL
    // const queryString = "?platformid=1&url=https://www.linkedin.com/in/princekumar294&encrypt=yes&cid=1&curl=www.recruitnxt.com"
    const queryString = window.location.search;
    console.log(queryString)
    const params = new URLSearchParams(queryString);
console.log(params)
    const PlatformidParamValue = params.get('platformid');
    const urlParamValue = params.get('url');
    const encryptParamValue = params.get('encrypt');
    const cidParamValue = params.get('cid');
    const sParamValue = params.get('s');
    const curlParamValue = params.get('curl');
console.log('PlatformidParamValue ',PlatformidParamValue)

 let getCookie = setInterval(() => {
  const cookieString = document.cookie;
  console.log('cookieString ',cookieString)
  console.log(cookieString != '')
  if(cookieString != ''){
    clearInterval(getCookie)
    console.log('cookieString ',cookieString)
    const cookies = cookieString.split(';');
for (let cookie of cookies) {
  const [cookieName, cookieValue] = cookie.split('=').map(c => c.trim());
  console.log(cookieName)
  if(cookieName == 'leadsNxt_sid' ){
    console.log('cookie found')
  console.log(cookieValue)
  console.log(sParam)
  // setSParam(cookieValue)
  if (localStorage.getItem('leadsNxt_sid') && sParam == cookieValue) {
    setSParam(localStorage.getItem('leadsNxt_sid'));
    fetchContactData();

  } else {
    setSParam(cookieValue  );
    localStorage.setItem('leadsNxt_sid', cookieValue  );
    fetchContactData();

  }
}
}
  }
}, 500);
    // Check if the values exist in local storage, and if not, use the query parameters
    if (localStorage.getItem('platformid')) {
      setPlatformidParam(localStorage.getItem('platformid'));
    } else {
      setPlatformidParam(PlatformidParamValue || '');
      localStorage.setItem('platformid', PlatformidParamValue || '');
    }

    if (localStorage.getItem('url')) {
      setUrlParam(localStorage.getItem('url'));
    } else {
      setUrlParam(urlParamValue || '');
      localStorage.setItem('url', urlParamValue || '');
    }

    if (localStorage.getItem('encrypt')) {
      setEncryptParam(localStorage.getItem('encrypt'));
    } else {
      setEncryptParam(encryptParamValue || '');
      localStorage.setItem('encrypt', encryptParamValue || '');
    }

    if (localStorage.getItem('cid')) {
      setCidParam(localStorage.getItem('cid'));
    } else {
      setCidParam(cidParamValue || '');
      localStorage.setItem('cid', cidParamValue || '');
    }

    if (localStorage.getItem('curl')) {
      setCurlParam(localStorage.getItem('curl'));
    } else {
      setCurlParam(curlParamValue || '');
      localStorage.setItem('curl', curlParamValue || '');
    }
  }, []);

  const updateIframeHeight = () => {

    if (sidebarRef.current) {
      const height = sidebarRef.current.scrollHeight;
      console.log(height);
      // Send the height to the parent window
      window.parent.postMessage({ iframeHeight: height }, '*');
    }
  };

  useEffect(() => {
    // When the component is mounted or contactData changes, update the iframe height
    updateIframeHeight();
  }, [contactData, isLoading,]);


  const fetchContactData = async () => {
    try {
      // Set the base URL for Axios
      axios.defaults.baseURL = 'https://leadsnxt-api.recruitnxt.in';

      // Create a FormData object
      const formData = new FormData();

      // Append key-value pairs to the FormData object for each form field
      // formData.append('sessionid', sParam);
      // formData.append('platformid', platformidParam);
      // formData.append('url', urlParam);
      // formData.append('encrypt', encryptParam);
      // formData.append('cid', cidParam);
      // formData.append('curl', curlParam);

      formData.append('sessionid', localStorage.getItem('leadsNxt_sid'));
      formData.append('platformid', localStorage.getItem('platformid'));
      formData.append('url', localStorage.getItem('url'));
      formData.append('encrypt', localStorage.getItem('encrypt'));
      formData.append('cid', localStorage.getItem('cid'));
      formData.append('curl', localStorage.getItem('curl'));

      // formData.append('sessionid', '9c661010-859d-497b-8ff1-9cb414a77565');
      // formData.append('platformid', 1);
      // formData.append('url', 'https://www.linkedin.com/in/princekumar294/');
      // formData.append('encrypt', 'yes');
      // formData.append('cid', 1);
      // formData.append('curl', 'www.recruitnxt.com');
      formData.forEach(function(value, key){
        console.log(key, value);
      });

      // Send a POST request to the API
      const response = await axios.post('/getdata.php', formData);

      if (response.data.result === 'success') {
        setDataMode(response.data.data_mode)
        console.log(response.data.person_details.contact_details.length)
   
        let contactData = response.data.person_details.contact_details;
        let socialMediaDetails = response.data.person_details.social_media;
        // contactData =[];
        // socialMediaDetails = []
        contactData.length == 0 ?contactData= [{"datatype": "phone","value": "Not Found" },{ "datatype": "email","value":"Not Found" }]:contactData;

        setContactData(contactData);
        setSocialMediaData(socialMediaDetails);
        console.log(socialMediaDetails);
        console.log('Data fetched successfully');

      } else {

        console.log('Data fetched failed');
      }
    } catch (error) {
      setItemGap('17px')
      setitemMarginBottom('14px')
      console.log('An error occurred:', error);

    }
  };


  // Separate email addresses and phone numbers
  let emailContacts = contactData.filter(contact => contact.datatype === 'email');
  let phoneContacts = contactData.filter(contact => contact.datatype === 'phone');

  if(emailContacts == ""){
    console.log('empty')
    emailContacts =  [{ "datatype": "email", "value":"Not Found" }]
  }
  if(phoneContacts == ""){
    console.log('empty')
    phoneContacts =  [{"datatype": "phone", "value": "Not Found"}]
  }
const [hoverEffect,setHoverEffect] = useState(Array(emailContacts.length).fill(false))
const [phoneHoverEffect,setPhoneHoverEffect] = useState(Array(phoneContacts.length).fill(false))
const [onhoverhow,setOnHoverShow] = useState(false)


  // function for second component----------------------------------------------------------------

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Option 2'); // Set the initial selected value
  const options = ['Option 1', 'Option 2', 'Option 3', 'Option 4'];
  const [showManageTags, setShowManageTags] = useState(false);
  const [tags, setTags] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [manageStatusdivEditable, setmanageStatus] = useState(false);
  const [showManageStatus, setShowManageStatus] = useState(false);

 
  

  const toggleManageTags = () => {
    if(contactData.length > 0){
    console.log('tagsclick');
    setmanageTagsdivEditable(!manageTagsdivEditable)
   overlay == ''?  setOverlay('overlay') : setOverlay('');

    setShowManageTags(!showManageTags);
    updateIframeHeight();
    // setNewTags(false);
    }
  };
  const toggleStatus= () => {
    if(contactData.length > 0){
    console.log('tagsclick');
    setmanageStatus(!manageStatusdivEditable)
   overlay == ''?  setOverlay('overlay') : setOverlay('');

    setShowManageStatus(!showManageStatus);
    // updateIframeHeight();
   
    }
  };

 

  const [newTags, setNewTags ] = useState(false)
  const addTag = () => {  
    setNewTags(true) ;
    console.log(selectedtags)
    const newUpdatedTags = [...updateSelectedTags]; // Copy the original array2

    selectedtags.forEach(option => {
      if (!newUpdatedTags.includes(option)) {
        newUpdatedTags.push(option); // Add the option to newUpdatedTags if it's not present
      }
    });

    setUpdateSelectedTags(newUpdatedTags)
   };
  
  const [newStatus, setNewStatus ] = useState(false)
 const addStatus =()=>{
  setNewStatus(true)
  setUpdateSelectedStatus(selectedStatus)
}

 console.log('newTag '+ newTags)


 

  return (
    <div  >
      { dataMode =='decrypted'?
        <ul className="list-group" style={{
    width: 'auto',
    top: '10px',
    display: 'grid',
    gap:` ${itemGap}`,
    position: 'absolute',
    listStyle: 'none',
    width: '396px',
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 4px 4px 0px',
    borderRadius: '9px',

} } ref={sidebarRef}>
          {isLoading ? (
            <li className="list-group-item"  style={{ height: '100%', width: '335px',  display:'flex', justifyContent:'end', marginTop: '-2px', marginLeft: '6px',position:'relative' }}>
              <Skeleton height={20} width={123} style={{ top: '10px' }} className="ad_leadnxt_logo" />
              <Skeleton height={20} style={{ width: '69px', height: '18px', top: '10px', left: '160px', position: 'absolute', fontSize: '16px', fontWeight: '400', lineHeight: '19px', letterSpacing: '0em', textAlign: 'left', color: '#2798EC' }} />
              <Skeleton style={{width: '21px',height: '21px',top: '10px',left: '300px',position: 'absolute',}}/>
              <Skeleton style={{width: '21px',height: '21px',top: '10px',left: '325px',border: '2.08px', position: 'absolute',}}/>
    
            </li>
          ) : (
            <li className="list-group-item" style={{ height: '100%', width: '335px',  display:'flex', justifyContent:'end', marginTop: '-2px', marginLeft: '6px',position:'relative' }}>
    <span>
    <img src={logoImg} alt="Leads NXT" className="ad_leadnxt_logo" />
    </span>
    <span>
    <span style={{ width: '69px', height: '18px', top: '19px', left: '132px', position: 'absolute', fontSize: '16px', fontWeight: '500', lineHeight: '19px', letterSpacing: '0em', textAlign: 'left', color: '#2798EC' }}>Upgrade</span>
    </span>
    <span style={{width: '16px',height: '17.28px',top: '23px',left: '311px',position: 'absolute',}}>
    <img src={vector} alt="icon" className="" />
    </span>
    <span  style={{width: '21px',height: '21px',top: '21.35px',left: '340px',border: '2.08px', position: 'absolute',}}><img src={settingImg} alt="setting" className="" /></span>
            </li>
          )}

          {isLoading ? (
            <li className="list-group-item" style={{ width: 'auto', height: '47px', marginTop: '2px', marginLeft: '0px', marginBottom: '10px', display: 'flex' }}>
              <Skeleton className="" height={40} width={236} style={{ top: '5px', marginLeft: '11px', marginRight: '10px' }} />
              <Skeleton className="" height={40} width={125} style={{top: '5px' }} />

            </li>
          ) : (

            <li className="list-group-item" style={{ width: 'auto', height: '47px', marginTop: '2px', marginLeft: '0px', marginBottom: `${itemMarginBottom}` }}>
              <button className="save_contact_btn" 
              style={{ color: 'rgb(25, 145, 235)', backgroundColor: 'rgb(255, 255, 255)', border: '1.5px solid rgb(25, 145, 235)',cursor: 'pointer' , 
                 width:' 170px',
              height: '32px',
              borderRadius: '5px',
              border: '1.5px',
              background: '#026DB3',
              border: '1.5px solid #026DB3',
              background: '#026DB3',
              color: 'white'}}> 
              <img src={profileImg} alt="" style={{marginTop: '4px',marginLeft: '-23px'}} className="cont_img" />
              <a target='_blank' style={{ textDecoration: 'none',fontSize: '14px',fontWeight: '600',lineHeight: '17px',letterSpacing:' 0em',textAlign: 'left',color: 'white', }} href="https://www.facebook.com/">User Profile</a></button>
              <button className="profile"  style={{cursor: 'pointer',left: '316px',width: '170px',height: '32px',left: '195px',borderRadius: '5px',position: 'absolute',background: 'white',border: '1.5px solid #D3DAE3', top: '52px', padding:'8px',zIndex:'1' }}>
               
             
          
          { divEditable ?
      <div className={overlay} style={{top:"-61px",height: '335px'}}>
            { isSuggestionOpen?
              <ul className='createDesignationBox' style={{height:"155px"}}>
              <li> <input placeholder='Create List' type='search'   value={addDesignation} className='createList' onChange={(e)=>{addDesig(e.target.value)}} />
         
                <img src={arrowImg} alt="" style={{
                width: '9.56px', height: '7.6px', top: '7.47px', left: '310.88px',
                position: 'relative',transform:`rotate(${tagRotateArrow}deg)`,cursor:'pointer'
              }} onClick={rotateArrowImg}  />
               </li>
              <li  >  <ListComponent list={profileArrList}  displayprofile={displaySelectedDesig} ></ListComponent></li>
              <li  > <SaveCancelComponent  toggleClick={profileCancel}   addTag={SaveDesignation} className={['profile_cancel_btn','profile_save_btn']}></SaveCancelComponent></li>
             
            
             </ul>
             :
             <ul className='createDesignationBox' >
             <li> <input placeholder='Create List' type='search'   value={addDesignation} className='createList' onChange={(e)=>{addDesig(e.target.value)}} />
        
               <img src={arrowImg} alt="" style={{
               width: '9.56px', height: '7.6px', top: '7.47px', left: '310.88px',
               position: 'relative',transform:`rotate(${tagRotateArrow}deg)`,cursor:'pointer'
              }} onClick={rotateArrowImg}  />
              </li>
             <li  style={{position: 'absolute', top: '-79px'}}> <SaveCancelComponent  toggleClick={profileCancel}   addTag={SaveDesignation} className={['profile_cancel_btn','profile_save_btn']}></SaveCancelComponent></li>
            
           
            </ul>
             }

      </div>: 
                 <span   style={{ color:' #949FB1', fontSize: '12px',fontWeight: '500',lineHeight: '15px',letterSpacing: '0em',textAlign: 'left',float: 'inline-start',marginLeft:'-5px', color: '#535355'
                }} onFocus={handleFocus} >{isSelectedProfile}{selectedProfileNum}</span>
             }
                <img src={arrowImg} alt="profileDropdown" style={{ marginTop: '3px',cursor: 'pointer' ,width: '10px', height: '8px',top: '9px',left: '123px',padding: '11.23px, 11.81px, 9.12px, 11.19px',cursor: 'pointer',position: 'absolute',transform:`rotate(${rotateArrow}deg)` }}
                onClick={profileDropdown} />
          

                
                { designationSaved ?  <img src={activeprofileSearch} alt="activeprofileSearch" style={{ cursor: 'pointer' ,width: '21px',height: '13px', top: '9px', left: '142px', border:' 1.8px',cursor: 'pointer',position: 'absolute' }}
                onClick={togggleProfileSearch} /> :
                <img src={createProfile} alt="createProfile" style={{ cursor: 'pointer' ,width: '21px',height: '13px', top: '9px', left: '142px', border:' 1.8px',cursor: 'pointer',position: 'absolute' }}
                onClick={togggleProfileSearch} />}
                
              </button>
              <SuggestionList suggestions={profileArrList} onSelect={handleSelectedProfiles} isSuggestionOpen={isProfileListOpen} tagRemove={removeCheck}  className={['profileList','profile']}/>
             
            </li>
          )}



          {emailContacts.map((contact, index) => (
            <>
              {isLoading ? (
                <li className='detail_list' style={{margin: '0px 25px 0px 62px'}}>
                  <Skeleton className="cont_img" width={20} height={20} />
                  <Skeleton width={200} height={20}   />
                  <Skeleton width={50} height={20} />
                </li>
              ) : (
            <li key={index} className= {hoverEffect[index] ?"detail_list_background": "detail_list"} onMouseOver={()=>{handleMouseOver(index)}} onMouseOut={()=>[handleMouseOut(index)]}   >

                <div className="contact_span">

                  <span>{contact.value}</span>
                 {contact.is_verified == 1? <img className= "cont_img" src={emailImg} style={{width:'22px',height:'22px'}} /> : <img className= "cont_img" src={mailImg} style={{width:'17px',height:'19px'}} />}
                {hoverEffect[index] ? 
               
                  <span className='sub_type'> 
                <div style={{width: '58px',
    height: '17px',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)'}}>  <img className= "" src={copyImg} style={{    width: '20.78px',
    height: '17.81px',
    marginTop: '0px',
    marginLeft: '3.19px',
    gap:' 0px',
    opacity:' 0px',
    position: 'static'}} />
                  <img className= " " src={mailImg} style={{width: '14.67px',
    height:' 14.33px',
    marginTop: '0px',
    marginLeft: '1.5px',
    gap: '0px',
    opacity: '0px',
    position: 'static'}} />
                  <img className= " " src={likeBtnImg} style={{    width: '16px',
    height: '15px',
    marginTop: '0px',
    marginLeft: '6px',
    padding: '0px 0.65px 0px 0px',
    gap: '0px',
    opacity: '0px',
    angle: '180 deg',position: 'static'}} /></div>
                  </span>
                  : contact.value !='Not Found'?<span className='sub_type'>{contact.sub_type}  </span>:<span className='sub_type'>Business</span>
                  }
                </div>
                </li>
              )}
              </>
            
          ))}

          {phoneContacts.map((contact, index) => (
            <>
            {isLoading ? (
                <li className='detail_list' style={{margin: '0px 25px 0px 62px'}}>
                  <Skeleton className="cont_img" width={20} height={20} />
                  <Skeleton width={200} height={20} />
                  <Skeleton width={50} height={20} />
                </li>
              ) : (
            <li key={index} className= {phoneHoverEffect[index] ?"detail_list_background": "detail_list"} onMouseOver={()=>{phoneHandleMouseOver(index)}} onMouseLeave={()=>{phoneHandleMouseOut(index)}}     >

                <div className='contact_span'>
                <span>
                {contact.value}
                </span>
                  <img className="cont_img" src={contactImg} style={{width:'16px',height:'18px'}} />
                  {phoneHoverEffect[index] ? 
               
               <span className='sub_type'> 
             <div style={{    width: '86px',
    height: '17px',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)'}}>
     <img className= "" src={editContactImg} style={{     marginTop: '0px',
    marginLeft: '9px',
    gap: '0px',
    width:' 14.68px',
    height: '13.63px',
 position: 'static'}} />
    <img className= "" src={copyImg} style={{  width: '20.78px',
    height: '17.81px',
    marginTop: '0px',
    marginLeft: '3.19px',
    gap:' 0px',
    opacity:' 0px',
    position: 'static'}} />
               <img className= " " src={contactImg} style={{width: '14.67px',
 height:' 14.33px',
 marginTop: '0px',
 marginLeft: '1.5px',
 gap: '0px',
 opacity: '0px',
 position: 'static'}} />
               <img className= " " src={likeBtnImg} style={{    width: '16px',
 height: '15px',
 marginTop: '0px',
 marginLeft: '6px',
 padding: '0px 0.65px 0px 0px',
 gap: '0px',
 opacity: '0px',
 angle: '180 deg',
 position: 'static'}} /></div>
               </span>
               : contact.value !='Not Found'?<span className='sub_type'>{contact.sub_type}  </span>:<span className='sub_type'>Mobile</span>
               }
                </div>
            </li>

              )}
              </>
          ))}

          
            {isLoading ? (
          <li className='detail_list' style={{margin: '0px 25px 0px 62px'}}>
            <Skeleton className="cont_img" width={20} height={20} />
            <Skeleton width={200} height={20} />
            <Skeleton width={50} height={20} />
          </li>
            ) : (
              <div className="contact_span">
              <img src={socialMediaImg} className="cont_img globe" style={{    width: '18px', height: '23px',  marginTop: '-2px'}}/>
              <ul style={{ display: 'inline-flex', listStyleType: 'none' }}>
              {/* Render social media links */}
              { socialMediaData.length >0  ? socialMediaData.map((socialMedia, index) => (
                <li key={index} className="social_media_span">
                  {isLoading ? (
                    <Skeleton width={20} height={20} style={{ marginRight: '10px' }} />
                  ) : (
                    <a href={socialMedia.url} target="_blank" rel="noopener noreferrer">
                      <img src={getSocialMediaImageName(socialMedia.data_type)} alt={socialMedia.data_type} style={{ marginRight: '10px' }} className="social_media_opt" />
                    </a>
                  )}


                </li>
              )):<span>Not Found</span>}
            </ul>
            <span className='sub_type' style={{cursor: 'default'}}>social media</span>
              </div>
            )}
           

         



            {isLoading ? (
              <li className='detail_list'  style={{margin: '0px 25px 0px 62px'}}>
                <Skeleton className="cont_img" width={19} height={18} style={{ left: '62px', position: 'absolute', padding: '0px' }} />
                <Skeleton width={108} height={25} style={{ marginRight: '70px' }} />
                <Skeleton width={73} height={20} style={{ cursor: 'pointer',  border: 'none', backgroundColor: 'white',marginTop: '8px' }} id="manage_tags" className='sub_type' />

              </li>
            ) : (
          <li className="detail_list" style={{ display: '',     marginBottom: '6px' ,marginTop:' -8px'}}>

              <div className="contact_span">
                <img src={tagImg} alt="" style={{ width: '19px', height: '18px', left: '62px', position: 'absolute', padding: '0px' }} className="cont_img" />

             <div style={{width:'225px'}}>
             {
               updateSelectedTags .length > 0? updateSelectedTags.map((tag,index)=>(
                  <span  key={index} className="selectedTags" style={{}}>{tag}</span>
                ))
                :  <span    className="" style={{}}>Not found</span>
              }
             </div>
                <span onClick={toggleManageTags} style={{ cursor: 'pointer',  border: 'none', backgroundColor: 'white' }} id="manage_tags" className='sub_type'> Manage Tags</span>

              </div>
                </li>
            )}
        
          
          { manageTagsdivEditable ?
      <div className={overlay} style={{left:'0px',top:'2px'}}>
          {showManageTags && (
            <div className="manage_tags" style={{ display: 'block',zIndex: 1,position: 'absolute',minHeight: '193px',maxHeight: '200px',overflowY: 'auto', }}>
            <ul className="manage_taglist">
             
              <li style={{ width: '323px', height: '61px', marginTop: '41px', marginLeft: '14px' }}>
              {/* <input value={inputValue} type="text" onChange={(e)=>handleInputValue(e.target.value)}     className="tag_create_input" /> */}
              <div  className="tag_create_input">
      
              { selectedtags.length >0 ? selectedtags.map((tag, index) => (
                <div>
                   <div key={index} className='tag_opt'>{tag}
     <span onClick={()=>(handleCancelClick(index,tag))} style={{ cursor: 'pointer', marginLeft: '5px' }}>
            &#x2716; 
          </span></div>
                </div>
   
)):''}
      <div>
        <input
          type="search"
         style={{ width: '274px', position: 'absolute',  top: '8px',  border:'none',  }}
          value={inputValue}
          onChange={(e)=>handleInputValue(e.target.value)} 
          onFocus={handleFocus}
        />
      </div>
    </div>
 
                <SuggestionList suggestions={suggestions} onSelect={handleSelectedSuggestion} isSuggestionOpen={isSuggestionOpen} tagRemove={removeCheck} className={['suggestionList','suggestion']} selectedOptions={selectedtags} />
                 {/* <img src={cross} alt="" className="tag_cross_btn" onClick={()=>cancelTagText()}  /> */}
                <img src={arrowImg} alt="" className="tag_cross_btn" onClick={rotateArrowImg } style={{ width: '12px',height: '9px',top:' -29px',left: '288px',transform:`rotate(${tagRotateArrow}deg)`,cursor:'pointer'}}/>
               
              </li>
             
              <li>
             <SaveCancelComponent toggleClick={toggleManageTags} addTag={addTag} className={['tag_cancel_btn','tag_save_btn']}></SaveCancelComponent>
              </li>
               
            
              <li className="new_tag_list">
                {tags.map((tag, index) => (
                <button className="tagList_opt">{tag}</button>
                ))}
                {/* <div style={{ left: '39px', cursor: 'pointer' }}>
                  <img src={deleteNoteImg} alt="" className="edit_tag" />
                  <img src={edit} alt="" className="delete_tag" />
                </div> */}
              </li> 
            </ul>
          </div>
          
          )}
          </div>:''}

            {isLoading ? (
              <li className="detail_list" style={{margin: '-4px 25px 0px 62px'}}>
                <Skeleton style={{ width: '19px', height: '18px', left: '62px', position: 'absolute', padding: '0px' }} className="cont_img"  />
                <Skeleton width={108} height={25} style={{ marginRight: '70px', }} />
                <Skeleton width={73} height={20} style={{ cursor: 'pointer' , border: 'none', backgroundColor: 'white' ,marginTop: '10px'}} id="manage_tags" className='sub_type'/>
              </li>
            ) : (
<li className="detail_list" style={{ display: '',  marginBottom: '14px',marginTop:' -8px' }}>

              <div className="contact_span">
                <img src={NAimg} alt="" style={{ width: '17px', height: '16px', left: '62px', position: 'absolute', padding: '0px' }} className="cont_img" />
                {
                updateSelectedStatus.length>0 ? updateSelectedStatus.map((status,index)=>(
                  <span  key={index} className="selectedTags" style={{color:'white',background:`${status.hashcodeColor}` }}>{status.status}</span>
                ))
                :                <span className="" style={{ marginRight: '53px',
                width: '51px',
                height: '25px',
                left: '42.75px',
                borderRadius: '5px',
                background:' #E8EEF4',
                fontSize: '14px',
                fontWeight: '500',
                lineHeight: '17px',
                letterSpacing: '0em',
                textAlign: 'left',
                textAlign: 'center',
                padding: '3px'
                
                }}>N/A</span>
              }
 
                <span onClick={toggleStatus}   style={{ cursor: 'pointer' , border: 'none', backgroundColor: 'white' }} id="manage_tags" className='sub_type'> Manage Status</span>
              </div>
                </li>
            )}

{ manageStatusdivEditable ?
      <div className={overlay} style={{left:'0px',top:'2px'}}>
          {showManageStatus && (
            <div className="manage_tags" style={{ display: 'block',zIndex: 1,position: 'absolute',minHeight: '193px',maxHeight: '200px',overflowY: 'auto', }}>
            <ul className="manage_taglist">
             
              <li style={{ width: '323px', height: '61px', marginTop: '41px', marginLeft: '14px' }}>
              {/* <input value={inputValue} type="text" onChange={(e)=>handleInputValue(e.target.value)}     className="tag_create_input" /> */}
              <div  className="tag_create_input">
      
              {selectedStatus.map((status, index) => (
                <div>
                   <div key={index} className='tag_opt'>{status.status}
     <span onClick={()=>(handleStatusCancelClick(index,status.status))} style={{ cursor: 'pointer', marginLeft: '5px' }}>
            &#x2716; 
          </span></div>
                </div>
   
))}
      <div>
        <input
          type="search"
         style={{ width: '274px', position: 'absolute',  top: '8px',  border:'none',  }}
          value={statusInputValue}
          onChange={(e)=>handleStatusInputValue(e.target.value)} 
          onFocus={handleFocus}
        />
      </div>
    </div>
 
                <StatusComponent suggestions={status} onSelect={handleSelectedStatus} isSuggestionOpen={isSuggestionOpen} tagRemove={removeCheck} className={['suggestionList','suggestion']} />
                 {/* <img src={cross} alt="" className="tag_cross_btn" onClick={()=>cancelTagText()}  /> */}
                <img src={arrowImg} alt="" className="tag_cross_btn" onClick={rotateArrowImg } style={{ width: '12px',height: '9px',top:' -29px',left: '288px',transform:`rotate(${tagRotateArrow}deg)`,cursor:'pointer'}}/>
               
              </li>
             
              <li>
             <SaveCancelComponent toggleClick={toggleStatus} addTag={addStatus} className={['tag_cancel_btn','tag_save_btn']}></SaveCancelComponent>
              </li>
               
            
              {/* <li className="new_tag_list">
                {status.map((tag, index) => (
                <button className="tagList_opt">{tag}</button>
                ))} */}
                {/* <div style={{ left: '39px', cursor: 'pointer' }}>
                  <img src={deleteNoteImg} alt="" className="edit_tag" />
                  <img src={edit} alt="" className="delete_tag" />
                </div> */}
              {/* </li>  */}
            </ul>
          </div>
          
          )}
          </div>:''}
        
         
        </ul>
        :
        <ul className="list-group" style={{
          width: 'auto',
          top: '10px',
          display: 'grid',
          gap:` ${itemGap}`,
          position: 'absolute',
          listStyle: 'none',
          width: '396px',
          boxShadow: 'rgba(0, 0, 0, 0.25) 0px 4px 4px 0px',
          borderRadius: '9px',
          cursor: 'default'
      
      } } ref={sidebarRef}>
                {isLoading ? (
                  <li className="list-group-item"  style={{ height: '100%', width: '335px',  display:'flex', justifyContent:'end', marginTop: '-2px', marginLeft: '6px',position:'relative' }}>
                    <Skeleton height={20} width={123} style={{ top: '10px' }} className="ad_leadnxt_logo" />
                    <Skeleton height={20} style={{ width: '69px', height: '18px', top: '10px', left: '160px', position: 'absolute', fontSize: '16px', fontWeight: '400', lineHeight: '19px', letterSpacing: '0em', textAlign: 'left', color: '#2798EC' }} />
                    <Skeleton style={{width: '21px',height: '21px',top: '10px',left: '300px',position: 'absolute',}}/>
                    <Skeleton style={{width: '21px',height: '21px',top: '10px',left: '325px',border: '2.08px', position: 'absolute',}}/>
          
                  </li>
                ) : (
                  <li className="list-group-item" style={{ height: '100%', width: '335px',  display:'flex', justifyContent:'end', marginTop: '-2px', marginLeft: '6px',position:'relative' }}>
          <span>
          <img src={logoImg} alt="Leads NXT" className="ad_leadnxt_logo" />
          </span>
          <span>
          <span style={{ width: '69px', height: '18px', top: '19px', left: '132px', position: 'absolute', fontSize: '16px', fontWeight: '500', lineHeight: '19px', letterSpacing: '0em', textAlign: 'left', color: '#2798EC' }}>Upgrade</span>
          </span>
          <span style={{width: '16px',height: '17.28px',top: '23px',left: '311px',position: 'absolute',}}>
          <img src={vector} alt="icon" className="" />
          </span>
          <span  style={{width: '21px',height: '21px',top: '21.35px',left: '340px',border: '2.08px', position: 'absolute',}}><img src={settingImg} alt="setting" className="" /></span>
                  </li>
                )}
      
                {isLoading ? (
                  <li className="list-group-item" style={{ width: 'auto', height: '47px', marginTop: '2px', marginLeft: '0px', marginBottom: '10px', display: 'flex' }}>
                    <Skeleton className="" height={40} width={236} style={{ top: '5px', marginLeft: '11px', marginRight: '10px' }} />
                    <Skeleton className="" height={40} width={125} style={{top: '5px' }} />
      
                  </li>
                ) : (
      
                  <li className="list-group-item" style={{ width: 'auto', height: '47px', marginTop: '2px', marginLeft: '0px', marginBottom: `${itemMarginBottom}` }}>
                    <button className="save_contact_btn" 
                    style={{ color: 'rgb(25, 145, 235)', backgroundColor: 'rgb(255, 255, 255)', border: '1.5px solid rgb(25, 145, 235)',cursor: 'pointer' , 
                    borderRadius: '5px',
                    border: '1.5px',
                    background: '#026DB3',
                    border: '1.5px solid #026DB3',
                    background: '#026DB3',
                    color: 'white',
                    width: '349.28px',height: '32px',top:' 56px',left:' 16px',gap: '9px',opacity: '0px',}}> 
                  <img src={profileImg} alt="" style={{marginTop: '4px',marginLeft: '-23px'}} className="cont_img" />
                    <a target='_blank' style={{ textDecoration: 'none',fontSize: '14px',fontWeight: '600',lineHeight: '17px',letterSpacing:' 0em',textAlign: 'left',color: 'white', }} href="https://www.facebook.com/">Save Contact</a></button>
                   
                  </li>
                )}
      
      
      
                {emailContacts.map((contact, index) => (
                  <>
                    {isLoading ? (
                      <li className='detail_list' style={{margin: '0px 25px 0px 62px'}}>
                        <Skeleton className="cont_img" width={20} height={20} />
                        <Skeleton width={200} height={20}   />
                        <Skeleton width={50} height={20} />
                      </li>
                    ) : (
                  <li key={index} className= "detail_list"    >
      
                      <div className="contact_span">
      
                        <span style={{    color: '#026DB3',paddingTop: '4px'}}>View email address</span>
                        <img className= "cont_img" src={emailImg} style={{width:'22px',height:'22px'}} />
                        <span className='sub_type'>Business</span>
                      </div>
                      </li>
                    )}
                    </>
                  
                ))}
      
                {phoneContacts.map((contact, index) => (
                  <>
                  {isLoading ? (
                      <li className='detail_list' style={{margin: '0px 25px 0px 62px'}}>
                        <Skeleton className="cont_img" width={20} height={20} />
                        <Skeleton width={200} height={20} />
                        <Skeleton width={50} height={20} />
                      </li>
                    ) : (
                  <li key={index} className="detail_list"    >
      
                      <div className='contact_span'>
                      <span style={{    color: '#026DB3',paddingTop: '4px'}}>
                      View mobile number
                      </span>
                        <img className="cont_img" src={contactImg} style={{width:'16px',height:'18px'}} />
                        <span className='sub_type'>Mobile</span>
                      </div>
                  </li>
      
                    )}
                    </>
                ))}
      
                
                  {isLoading ? (
                <li className='detail_list' style={{margin: '0px 25px 0px 62px'}}>
                  <Skeleton className="cont_img" width={20} height={20} />
                  <Skeleton width={200} height={20} />
                  <Skeleton width={50} height={20} />
                </li>
                  ) : (
                    <div className="contact_span">
                    <img src={socialMediaImg} className="cont_img globe" style={{    width: '18px', height: '23px',  marginTop: '-2px'}}/>
                    <ul style={{ display: 'inline-flex', listStyleType: 'none' }}>
                    {/* Render social media links */}
                    { socialMediaData.length >0  ?  
                      <li  className="social_media_span">
                        {isLoading ? (
                          <Skeleton width={20} height={20} style={{ marginRight: '10px' }} />
                        ) : (
                          <span style={{    color: '#026DB3',paddingTop: '4px'}}>View external links</span>
                        )}
      
      
                      </li>
                    :<span>Not Found</span>}
                  </ul>
                  <span className='sub_type' style={{cursor: 'default'}}>social media</span>
                    </div>
                  )}
                 
      
               
      
      
      
                  {isLoading ? (
                    <li className='detail_list'  style={{margin: '0px 25px 0px 62px'}}>
                      <Skeleton className="cont_img" width={19} height={18} style={{ left: '62px', position: 'absolute', padding: '0px' }} />
                      <Skeleton width={108} height={25} style={{ marginRight: '70px' }} />
                      <Skeleton width={73} height={20} style={{ cursor: 'pointer',  border: 'none', backgroundColor: 'white',marginTop: '8px' }} id="manage_tags" className='sub_type' />
      
                    </li>
                  ) : (
                <li className="detail_list" style={{ display: '',     marginBottom: '6px' ,marginTop:' -8px'}}>
      
                    <div className="contact_span">
                      <img src={tagImg} alt="" style={{ width: '19px', height: '18px', left: '62px', position: 'absolute', padding: '0px' }} className="cont_img" />
      
                   <div style={{width:'225px'}}>
                   {
                      true ? 
                        <span className='selectedTags'>1 Found</span>
                      :  <span >Not found</span>
                    }
                   </div>
                      <span  style={{ cursor: 'pointer',  border: 'none', backgroundColor: 'white' }} id="manage_tags" className='sub_type'> Manage Tags</span>
      
                    </div>
                      </li>
                  )}
              
                
                { manageTagsdivEditable ?
            <div className={overlay} style={{left:'0px',top:'2px'}}>
                {showManageTags && (
                  <div className="manage_tags" style={{ display: 'block',zIndex: 1,position: 'absolute',minHeight: '193px',maxHeight: '200px',overflowY: 'auto', }}>
                  <ul className="manage_taglist">
                   
                    <li style={{ width: '323px', height: '61px', marginTop: '41px', marginLeft: '14px' }}>
                    {/* <input value={inputValue} type="text" onChange={(e)=>handleInputValue(e.target.value)}     className="tag_create_input" /> */}
                    <div  className="tag_create_input">
            
                    {selectedtags.map((tag, index) => (
                      <div>
                         <div key={index} className='tag_opt'>{tag}
           <span onClick={()=>(handleCancelClick(index,tag))} style={{ cursor: 'pointer', marginLeft: '5px' }}>
                  &#x2716; 
                </span></div>
                      </div>
         
      ))}
            <div>
              <input
                type="search"
               style={{ width: '274px', position: 'absolute',  top: '8px',  border:'none',  }}
                value={inputValue}
                onChange={(e)=>handleInputValue(e.target.value)} 
                onFocus={handleFocus}
              />
            </div>
          </div>
       
                      <img src={arrowImg} alt="" className="tag_cross_btn" onClick={rotateArrowImg } style={{ width: '12px',height: '9px',top:' -29px',left: '288px',transform:`rotate(${tagRotateArrow}deg)`,cursor:'pointer'}}/>
                     
                    </li>
                   
                    <li>
                   <SaveCancelComponent toggleClick={toggleManageTags} addTag={addTag} className={['tag_cancel_btn','tag_save_btn']}></SaveCancelComponent>
                    </li>
                     
                  
                    <li className="new_tag_list">
                      {tags.map((tag, index) => (
                      <button className="tagList_opt">{tag}</button>
                      ))}
                      {/* <div style={{ left: '39px', cursor: 'pointer' }}>
                        <img src={deleteNoteImg} alt="" className="edit_tag" />
                        <img src={edit} alt="" className="delete_tag" />
                      </div> */}
                    </li> 
                  </ul>
                </div>
                
                )}
                </div>:''}
      
                  {isLoading ? (
                    <li className="detail_list" style={{margin: '-4px 25px 0px 62px'}}>
                      <Skeleton style={{ width: '19px', height: '18px', left: '62px', position: 'absolute', padding: '0px' }} className="cont_img"  />
                      <Skeleton width={108} height={25} style={{ marginRight: '70px', }} />
                      <Skeleton width={73} height={20} style={{ cursor: 'pointer' , border: 'none', backgroundColor: 'white' ,marginTop: '10px'}} id="manage_tags" className='sub_type'/>
                    </li>
                  ) : (
      <li className="detail_list" style={{ display: '',  marginBottom: '14px',marginTop:' -8px' }}>
      
                    <div className="contact_span">
                      <img src={NAimg} alt="" style={{ width: '17px', height: '16px', left: '62px', position: 'absolute', padding: '0px' }} className="cont_img" />
                      <span className="" style={{ marginRight: '53px',
          width: '51px',
          height: '25px',
          left: '42.75px',
          borderRadius: '5px',
          background:' #E8EEF4',
          fontSize: '14px',
          fontWeight: '500',
          lineHeight: '17px',
          letterSpacing: '0em',
          textAlign: 'left',
          textAlign: 'center',
          padding: '3px'
          
          }}>N/A</span>
                      <span    style={{ cursor: 'pointer' , border: 'none', backgroundColor: 'white' }} id="manage_tags" className='sub_type'> Manage Status</span>
                    </div>
                      </li>
                  )}
              
               
              </ul>
      }
    </div>

  );
}

function getSocialMediaImageName(dataType) {
  switch (dataType) {
    case 'facebook':
      return facebookImg; // Replace with the actual image filename for Facebook
    case 'twitter':
      return twitterImg; // Replace with the actual image filename for Twitter
    case 'linkedIn':
      return linkedinImg; // Replace with the actual image filename for LinkedIn
    default:
      return ''; // Handle other social media types or return a default image
  }
}

export default Sidebarbox;
